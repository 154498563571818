@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#txContainer {
  position: relative;
}
/////////////////////////////////
#txContainer > .inner > .item {
  .imgStyle {
    display: block;
    position: relative;
    img {
      @include imgset;
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}

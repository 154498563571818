@charset "utf-8";
// vars
@mixin clearfix {
    clear: both;
    &:after {
        content: " ";
        display: block;
        clear: both;
    }
}

@mixin futura{
  font-family: futura-pt, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin arrow_01{
  width: 42/$design-num-sp*100vw;
  height: 42/$design-num-sp*100vw;
  border-radius: 100%;
  background-color: $key-color;
  text-align: center;
  display: block;
  position: absolute;
  $ar-gutter:21/$design-num-sp*100vw;
  top: -webkit-calc(50% - #{$ar-gutter});
  top: calc(50% - #{$ar-gutter});
  left: 0;
  transition: .3s;
  i{
    color: $white;
    line-height: 42/$design-num-sp*100vw;
    @include fz_vw(18);
  }
  @media screen and (min-width: $break-point-middle) {
  width: 18px;
  height: 18px;
  top: -webkit-calc(50% - .9rem);
  top: calc(50% - .9rem);
  i{
    line-height: 18px;
    font-size: 1.0rem;
  }
  }
}

@mixin arrow_02{
  width: 34/$design-num-sp*100vw;
  height: 34/$design-num-sp*100vw;
  border-radius: 100%;
  background-color: $key-color;
  text-align: center;
  display: block;
  position: absolute;
  $ar-gutter:15/$design-num-sp*100vw;
  top: -webkit-calc(50% - #{$ar-gutter});
  top: calc(50% - #{$ar-gutter});
  left: 0;
  transition: .3s;
  i{
    color: $white;
    line-height: 34/$design-num-sp*100vw;
    @include fz_vw(18);
  }
  @media screen and (min-width: $break-point-middle) {
  width: 18px;
  height: 18px;
  top: -webkit-calc(50% - .9rem);
  top: calc(50% - .9rem);
  i{
    line-height: 18px;
    font-size: 1.0rem;
  }
  }
}

@mixin glad_01{
  color: #e36eb1;//非対応のブラウザでの文字色を設定
  background: linear-gradient(to right, #e36eb1, #9d6ec5);//背景色にグラデーションを指定
  -webkit-background-clip: text;//テキストでくり抜く
  -webkit-text-fill-color: transparent;//くり抜いた部分は背景を表示
}
///decorate
@mixin underShadow{
	@include box-shadow( 0 5px 5px -3px rgba($black, 0.1));
}

@mixin topShadow{
	@include box-shadow( 0 8px 12px -8px rgba($black, 0.8));
}

@mixin basicShadow{
	@include box-shadow(rgba(0, 0, 0, 0.2) 0px 0px 6px 3px);
}
///////post
@mixin postTtl_01{
  color: $key-color;
  @include fz_vw(42);
  line-height: (50/42)+em;
  margin-bottom: 40/$design-num-sp*100vw;
  @media screen and (min-width: $break-point-middle) {
  font-size: 3.2rem;
  margin-bottom: 30px;
  }
}

@mixin postTtl_02{
  line-height: (50/42)+em;
  margin-bottom: 26/$design-num-sp*100vw;
  @include fz_vw(36);
  @media screen and (min-width: $break-point-middle) {
  font-size: 2.5rem;
  margin-bottom: 16px;
  }
}

@mixin postP_01{
  line-height: (48/30)+em;
  @include fz_vw(30);
  >*{
    margin-bottom: 60/$design-num-sp*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: $break-point-middle) {
  line-height: (28/17)+em;
  font-size: 1.7rem;
  >*{
    margin-bottom: 30px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  }
}

///outLineo
@mixin contentWrap($base-width:$content-width){
	position:relative;
  max-width: $base-width;
  margin: 0 auto;
  padding-left: $base-pad;
  padding-right: $base-pad;
}

@mixin setTbl{
	display:table;
	width:100%;
	table-layout:fixed;
	>*{
		display:table-cell;
		vertical-align:middle;
		text-align:left;
	}
}

@mixin basicLink{
	color:$link-color;
	text-decoration:none;
	&:hover{
		text-decoration:underline;
		color:$hover-color;
	}
	&.ex:before{
		font-family: 'FontAwesome';
		content:"\f08e";
		padding:0 4px;
		text-decoration:none!important;
	}
}

@mixin btnsetting{
  outline: 0;
  @include appearance(none);
  cursor: pointer;
  border: none;
  padding: 0;
  @include box-shadow(none);
}
//////////////////////
@mixin dotted($color:rgba(#000, 1.0), $stripe: 1, $spacing: 4, $height:1){
	background-image:(linear-gradient(left, color-stops($color, $color ($stripe/($stripe+$spacing))*100%, transparent ($stripe/($stripe+$spacing))*100%, transparent 100%)));
	background-size: ($stripe+$spacing)*1px ($stripe+$spacing)*1px;
	height: $height*1px;
	border: none;
}
//////////////////////
@mixin dotbox($color:$black,$stripe: 3, $spacing: 5, $height: 1){
  position: relative;
  &::before{
    @include transition(.3s);
    content: "";
    background-image: linear-gradient(to right, $color, $color $stripe*1px, transparent $stripe*1px, transparent ($stripe+$spacing)*1px),
    linear-gradient(to right, $color, $color $stripe*1px, transparent $stripe*1px, transparent ($stripe+$spacing)*1px),
    linear-gradient(to bottom, $color, $color $stripe*1px, transparent $stripe*1px, transparent ($stripe+$spacing)*1px),
    linear-gradient(to bottom, $color, $color $stripe*1px, transparent $stripe*1px, transparent ($stripe+$spacing)*1px);
    background-size: ($stripe+$spacing)*1px $height*1px, ($stripe+$spacing)*1px $height*1px, $height*1px ($stripe+$spacing)*1px, $height*1px ($stripe+$spacing)*1px;
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
//////////////////////
@mixin box{
	@include box-sizing(border-box);
}
//////////////////////
@mixin iconFont{
	font-family: "myfont";
}
//////////////////////
@mixin killSpace{
	letter-spacing: -.4em;
	>*{
		display:inline-block;
		letter-spacing: normal;
	}
}
//////////////////////
@mixin inputStyle_01{
	@include appearance(none);
	margin: 0;
	border: none;
  border-radius: 0px;
	outline: none;
	@include fz_vw(32);
	line-height: 1.6em;
	padding-left: 10/375*100vw;
	padding-right: 10/375*100vw;
	@media screen and (min-width: $break-point-middle) {
		font-size:1.8rem;
    padding-left: 10px;
    padding-right: 10px;
	}
	border:1px solid $key-gray2;
	color:$text-color!important;
	min-height:3em;
	@include box;
	width:100%;
	//background-color:#FCFCFC;
  background-color: $white;
  @include transition(.2s);
  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"]{
    -moz-appearance:textfield;
  }
  &.err{
    //border: 1px solid $err-color;
    background-color: $white;
  	background-color:$err-color;
  }
	&:focus{
  	background-color:$white;
		border:1px solid $key-color;
	}
	/* 各ベンダープレフィックスが取れた標準版！！(http://caniuse.com/#search=placeholder-shown) */
	&:placeholder-shown {
		color:$key-gray2;
	}

	/* Google Chrome, Safari, Opera 15+, Android, iOS */
	&::-webkit-input-placeholder {
		color:$key-gray2;
	}

	/* Firefox 18- */
	&:-moz-placeholder {
		color:$key-gray2;
	}

	/* Firefox 19+ */
	&::-moz-placeholder {
		color:$key-gray2;
	}

	/* IE 10+ */
	&:-ms-input-placeholder {
		color:$key-gray2;
	}
}

//////////////////////
@mixin lineOver{
  color: $text-color;
  &:after{
    content: '';
    display: block;
    height: 1px;
    background-color: $key-black;
    width: 0;
    @include transition(.3s ease-in-out);
  }
  &:hover{
    @include opacity(.8);
  }
  &:hover:after{
	@media screen and (min-width: $break-point-middle) {
    width: 100%;
	}
  }
  &.current{
    cursor: default;
    pointer-events: none;
    &:after{
      width: 100%;
      @include opacity(.8);
    }
  }
}

/////////////////////
@mixin contentMax{
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 70px;
  padding-right: 70px;
}

/////////////////////
@mixin over_00($color:$black){
  position: relative;
  &:before{
    content: '';
    height: 1px;
    width: 0%;
    bottom: 0;
    position: absolute;
    @include transition(.3s);
    background-color: $color;
  }
}
/////////////////////
@mixin over_01($color:$black){
  position: relative;
  //padding-bottom: 3px;
  &:before{
    content: '';
    height: 1px;
    width: 0%;
    left: 0;
    //background-color: $hover-color;
    bottom: 0;
    position: absolute;
    @include transition(.3s);
    background-color: $color;
  }
  @media screen and (min-width: $break-point-middle) {
  &:hover:before{
    width: 100%;
  }
  }
}
/////////////////////
@mixin over_02($color:$black,$pos:0px){
  position: relative;
  &:before{
    content: '';
    height: 1px;
    width: 100%;
    background-color: $color;
    bottom: $pos;
    position: absolute;
    @include transform(translateX(0%));
  }
  @media screen and (min-width: $break-point-middle) {
  &:hover:before{
    animation:linemove_01 0.8s cubic-bezier(0.58, 0.3, 0.005, 1) 0s 1;
  }
  }
}
/////////////////////
@mixin over_03($color:$white){
  position: relative;
  &:before{
    content: '';
    height: 1px;
    width: 100%;
    background-color: $color;
    bottom: 0;
    position: absolute;
    @include transition($ease_01);
    @include scale(0);
    @include transform-origin(50%,50%);
    //@include transform(translateX(-100%));
  }
  @media screen and (min-width: $break-point-middle) {
  &:hover:before{
    @include scale(1);
  }
  }
}
/////////////////////
@mixin over_04($color:$white){
  position: relative;
  &:after{
    content: '';
    height: 2px;
    background-color: $color;
    position: absolute;
    width: 0%;
    top: -webkit-calc(50% - 1px);
    top: calc(50% - 1px);
    left: 0;
    @include transition($ease_01);
  }
  &.current::after{
    width: 100%;
  }
  @media screen and (min-width: $break-point-middle) {
  &:hover:after{
    width: 100%;
  }
  }
}
/////////////////////
@mixin over_05{
  position: relative;
  padding-bottom: 1px;
  &:before{
    content: '';
    height: 1px;
    width: 0%;
    background-color: $key-black;
    bottom: 0;
    position: absolute;
    @include transition(.3s);
  }
  @media screen and (min-width: $break-point-middle) {
  &:hover:before{
    width: 100%;
  }
  }
}
@mixin over_06($color){
  position: relative;
  overflow: hidden;
  >span{
    position: relative;
  }
  @media screen and (min-width: $break-point-middle) {
  &::before{
    content: '';
    width: 100%;
    display: block;
    top: 0;
    left: -100%;
    bottom:0;
    transition: .3s;
    background-color: $color;
    position: absolute;
  }
  &:hover{
    &::before{
      left: 0;
    }
  }
  }
}
//////////////////////
@mixin maskFade_01{
  >span{
    display: block;
    position: relative;
    left: -120%;
    transition: 1.3s;
    &::after{
      content: '';
      position: absolute;
      display: block;
      width: 120%;
      top: 0;
      left: 0;
      bottom: 0;
      background-color: $key-color;
      transition: .7s linear .7s;
      transform: scale(1,1);
      transform-origin: 100% 0;
    }
  }
  .is-active-item &{
    >span{
      left: 0%;
      &::after{
        transform: scale(0,1);
      }
    }
  }
}
//////////////////////
@mixin maskFade_02{
  >*{
    overflow: hidden;
    position: relative;
    left: -100%;
    @include transition(1.3s);
  }
  >*>*:after{
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $white;
    @include transition(.7s);
    @include scale(1,1);
    @include transform-origin(100%,0%);
    .active &{
    }
  }
  &.active{
    >*{
      left: 0;
    }
    >*>*:after{
      @include scale(0,1);
      @include transition-delay(.7s);
    }
  }
}
//////////////////////
@mixin tagit{
  .ui-widget-content{
    @include border-radius(6px);
    border:1px solid #E2E2E2;
  }
  ul.tagit{
    padding: 12px 200px 12px 24px;
    //overflow: auto;
    //white-space: nowrap;
  }
  ul.tagit input[type=text]{
    border: none;
  }
  ul.tagit li{
    //display: inline-block;
    //float: none;
  }
  ul.tagit li input{
    min-width: 20em;
    font-size: 1.6rem;
  }
  ul.tagit li.tagit-choice-editable{
    padding-right: 30px;
  }
  ul.tagit li.tagit-choice .tagit-close{
    right: .5em;
  }
  .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default{
    background-color: $key-blue2;
    background-image: none;
    color: $white;
    border: none;
  }
  .ui-icon-close{
    background-image: none;
    text-indent: 0;
    &:before{
      content: '';
      @include iconFont;
      content: "\E004";
      color: $white;
      font-size: 1.4rem;
      line-height: 1em;
    }
  }
  .tagit-label{
    font-size: 1.6rem;
  }
}
//////////////////////
@mixin resetTbl{
  table{
    display: table;
  }
  tbody{
    display: table-row-group;
  }
  thead{
    display: table-header-group;
  }
  tr{
    display: table-row;
  }
  th,td{
    display: table-cell;
  }
}
///////////////////////
@mixin imgset{
  max-width: none;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  // -webkit-backface-visibility: hidden;
  // backface-visibility: hidden;
  // image-rendering: -webkit-optimize-contrast;
  // @media all and (-ms-high-contrast: none) {
  //   width: auto;
  // }
}
///////////////////////
@mixin kvshadow{
  @include filter(drop-shadow(0px 0px 10px rgba(18,126,180,.7)));
}
///////////////////////
@mixin shadowStyle_01{
  @include box-shadow(0px 0px 20px -6px rgba(18,126,172,.8));
}
///////////////////////
@mixin bgRatio{
  &.style1x{
    display: block;
  }
  &.style2x,&.style3x{
    display: none;
  }
  @media screen and (-webkit-device-pixel-ratio: 2) {
    &.style2x{
      display: block;
    }
    &.style1x,&.style3x{
      display: none;
    }
  }
  @media screen and (-webkit-device-pixel-ratio: 3) {
    &.style3x{
      display: block;
    }
    &.style1x,&.style2x{
      display: none;
    }
  }
  @media screen and (min-width: $break-point-middle) {
    &.spElem{
      display: none;
    }
  }
}

///////
@mixin bounceY($basespeed:0s,$efspeed:.03){
  position: relative;
  // opacity: 0;
  // transform: translateY(1em);
  @for $i from 1 through 40 {
    &:nth-child(#{$i}){
      //@include transform-origin(0%,$i+em);
      //@include transition-delay(0s + $efspeed*$i);
      animation: bounceY .8s ($basespeed + $efspeed*$i) forwards;
    }
  }
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@keyframes bounceY {
  0% {
    transform: translateY(1em);
    opacity: 0;
  }
  // 50% {
  //   transform: translateY(-10px);
  //   opacity: 1;
  // }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
/////////////////////////
@keyframes line_animation {
  0% {
    stroke-dashoffset: 2000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes line_animation2 {
  0% {
    stroke-dashoffset: 100;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
/////////////////////////
@keyframes line_animation3 {
  0% {
    stroke-dashoffset: 2000;
  }
  80% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 0;
  }
}
@keyframes line_animation4 {
  0% {
    stroke-dashoffset: 100;
  }
  80% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 0;
  }
}
/////////////////////////////
@keyframes sizeScale {
	0%{
		@include scale(1);
	}
  100% {
	@include scale(1.1);
  }
}
/////////////////////////////
@keyframes handwriting_01 {
  0% {
    opacity: 0;
    transform: translateY(1em);
    stroke-dashoffset: 2000;
    fill:transparent;
  }
  50% {
    opacity: 1;
    transform: translateY(0);
    fill:transparent;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    stroke-dashoffset: 0;
    fill:$white;
  }
}
/////////////////////////////
@mixin txEffectFormat_01 {
  overflow: hidden;
	position: relative;
	padding-top: .3em;
  opacity: 0;
  .loaded &{
    opacity: 1;
  }
	>span>span{
		position: relative;
		display: inline-block;
		opacity: 0;
		top:2em;
		letter-spacing: .07em;
	}
}
@keyframes txEffect_01 {
  0% {
		opacity: 0;
		top:2em;
	}
	80% {
		top:-.3em;
	}
	100% {
		opacity: 1;
		top: 0;
	}
}
/////////////////////////////
@mixin txEffectFormat_02 {
  opacity: 0;
	transform: translateY(20px);
	transition: 1s $ease_04;
}
/////////////////////////////
@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
// 第一引数で基準となる画面幅のフォントサイズ、第二引数で基準となる画面幅を入力
@function get_vw($size, $viewport:$design-num-sp){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size:10){
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}
////
@function get_vwpc($size, $viewport:$design-num){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin fz_vwpc($font_size:10){
  font-size: $font_size * 1px;
  font-size: get_vwpc($font_size);
}
//////////////////////

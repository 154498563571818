@charset "utf-8";
i{
	//line-height:0!important;
	vertical-align:top;
	//padding:0 3px;
  &:before{
    vertical-align: top;
  }
}
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
.lazyload {
	opacity: 0;
	//transform: scale(0.8);
	@include transition(.3s)
}

.lazyloaded {
	opacity: 1;
	//@include transition(.3s)
	//transform: scale(1);
	//transition: all 700ms;
}

.lazyloaded + .loadImg{
	display:none!important;
}
/*.lazyload, .lazyloading {
 opacity: 0;
}
.lazyloaded{
  opacity: 1;
  transition: all 0.5s;
}*/
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
.setSp .sp-way{
	opacity: 0;
	transform: translateY(10px);
	transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
	&.is-active-sp{
		opacity: 1;
		transform: translateY(0);
	}
}
.way{
	@include opacity(0);
	@include transform(translateY(30px));
	@include transition(all 1s cubic-bezier(0.645, 0.045, 0.355, 1));
	&.is-active,.showFlag &{
		@include opacity(1);
		@include transform(translateY(0px));
	}
}
.autoWay{
	@include opacity(0);
	@include transform(translateY(30px));
	@include transition(all 1s cubic-bezier(0.645, 0.045, 0.355, 1));
	.loaded &{
		@include opacity(1);
		@include transform(translateY(0px));
	}
}
@media screen and (min-width: $break-point-middle) {
.autoWay{
	@include opacity(0);
	@include transform(translateY(30px));
	@include transition(all 1s cubic-bezier(0.645, 0.045, 0.355, 1));
	.loaded &{
		@include opacity(0);
		@include transform(translateY(30px));
	}
	&.active{
		@include opacity(1);
		@include transform(translateY(0px));
	}
}
}
.fastWay{
	@include opacity(0);
	@include transform(translateY(30px));
	@include transition(all 1s cubic-bezier(0.645, 0.045, 0.355, 1));
	&.active,.showContFlag &{
		@include opacity(1);
		@include transform(translateY(0px));
	}
}
.alphaWay{
	@include opacity(0);
	@include transition(all .6s cubic-bezier(0.645, 0.045, 0.355, 1) 0s);
	&.is-active{
		@include opacity(1);
	}
}
.scaleWay{
	@include opacity(0);
	@include transition(all 0.9s $ease-in-out-circ);
	@include transform(scale(0));
	@include transform-origin(50%,50%);
	//@include transform(rotate(0deg));
	&.active{
		@include opacity(1);
		@include transform(scale(1));
		//@include transform(rotate(360deg));
	}
}
////////////////////////////////////////////////
////////////////////////////////////////////////
.imgEffect_01{
	>span{
		display: block;
		transition: 1s $ease-out-quad;
		overflow: hidden;
		position: relative;
		left: -100%;
		opacity: 0;
		img{
			position: relative;
			left: 100%;
			transition: 1s $ease-out-quad;
		}
	}
	&.is-active{
		>span{
			left: 0;
			opacity: 1;
			img{
				left: 0;
			}
		}
	}
}
/////////////////////////////////
.imgEffect_02{
	@extend .imgEffect_01;
	>span{
		left: auto;
		right: -100%;
		img{
			left: auto;
			right: 100%;
		}
	}
	&.is-active{
		>span{
			left: auto;
			right: 0;
			img{
				left: auto;
				right: 0;
			}
		}
	}
}
////////////////////////////////////////
.imgEffect_03{
	display: block;
	opacity: 0;
	transform: translateY(30);
	transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) .5s;
	&.is-active{
		opacity: 1;
		transform: translateY(0);
	}
}
////////////////////////////////////////
.imgEffect_04{
	overflow: hidden;
	position: relative;
	&::before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		background-color: $white;
		.is-active &{
			animation: .6s slideOutRight $ease-out-quad .1s forwards;
		}
	}
}
////////////////////////////////////////
.imgEffect_05{
	display: block;
	opacity: 0;
	transform: translateY(30);
	transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) .5s;
	.is-active &{
		opacity: 1;
		transform: translateY(0);
	}
}
////////////////////////////////////////
.imgEffect_06{
	display: block;
	opacity: 0;
	transform: translateY(30);
	transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) .5s;
	&.is-active{
		opacity: 1;
		transform: translateY(0);
	}
}
@media screen and (min-width: $break-point-middle) {
.imgEffect_06{
	opacity: 1;
	transform: translateY(0);
}
}
////////////////////////////////////////////////
////////////////////////////////////////////////
.txEffect_01{
	@include txEffectFormat_01;
	>span{
		display: block;
	}
	>span>span{
		@for $i from 1 through 40 {
			&:nth-child(#{$i}){
				.is-active &{
					animation:txEffect_01 .8s $ease-in-out-sine 0s + .02*$i forwards;
				}
			}
		}
	}
	&.style_01{
		>span>span:nth-child(10){
			display: block;
			height: 0;
		}
	}
}
@media screen and (min-width: $break-point-middle) {
.txEffect_01{
	&.style_01{
		>span>span:nth-child(10){
			display: inline;
			height: auto;
		}
	}
}
}
///////////////////////////////
.txEffect_02{
	@include txEffectFormat_02;
	.is-active &{
		opacity: 1;
		transform: translateY(0);
	}
}
///////////////////////////////
.txEffect_03{
	@include txEffectFormat_01;
	>span>span{
		@for $i from 1 through 40 {
			&:nth-child(#{$i}){
				.is-active-item &{
					animation:txEffect_01 .8s $ease-in-out-sine 0s + .02*$i forwards;
				}
			}
		}
	}
}
///////////////////////////////
.txEffect_04{
	@include txEffectFormat_02;
	.is-active-item &{
		opacity: 1;
		transform: translateY(0);
	}
}
////////////////////////////////////////////////
////////////////////////////////////////////////
.hiddenParts{
  display: none;
}
////////////////////////////////////////////////
////////////////////////////////////////////////
@keyframes linemove_01{
  0% {
    @include transform(translateX(0));
  }
  50% {
    @include transform(translateX(100%));
  }
  50.01% {
    @include transform(translateX(-100%));
  }
  to {
    @include transform(translateX(0));
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.grecaptcha-badge{
	display: none;
	bottom: 200/750*100vw!important;
	.page-template-page-order &,.page-template-page-contact &,.page-template-page-trial &{
		display: block;
	}
}
@media screen and (min-width: $break-point-middle) {
.grecaptcha-badge{
	bottom: 100px!important;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.scrollSnap{
  // scroll-snap-type: y mandatory;
  // //proximity
  // overflow: auto;
  // height: 100vh;
  >*{
    // scroll-snap-align: start;
    // display: flex;
    // min-height: 100vh;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.desktop.setSp .luxy-el:not(.luxy-el.mainCatch){
	transform: none!important;
}
.desktop.setSp .luxy-el.mainCatch{
	transform: rotate(90deg)!important;
}
.desktop.setSp.home .luxy-el.mainCatch{
	transform: none!important;
}
@media screen and (min-width: $break-point-middle) {
.desktop.setSp .luxy-el.mainCatch{
	transform: rotate(0deg)!important;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

@font-face {
    font-family: "myfont";
    src: url('../fonts/myfont.eot');
    src: url('../fonts/myfont.eot?#iefix') format('eot'),
        url('../fonts/myfont.woff') format('woff'),
        url('../fonts/myfont.ttf') format('truetype'),
        url('../fonts/myfont.svg#myfont') format('svg');
    font-weight: normal;
    font-style: normal;
}
.myfont {
    display: inline-block;
    font-family: "myfont";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.myfont-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.myfont-2x { font-size: 2em; }
.myfont-3x { font-size: 3em; }
.myfont-4x { font-size: 4em; }
.myfont-5x { font-size: 5em; }
.myfont-fw {
    width: 1.2857142857142858em;
    text-align: center;
}
.myfont-arrow_01:before { content: "\EA01" }
.myfont-radio-off:before { content: "\EA02" }
.myfont-radio-on:before { content: "\EA03" }
.myfont-check-off:before { content: "\EA04" }
.myfont-check-on:before { content: "\EA05" }
.myfont-icon-01:before { content: "\EA06" }
.myfont-icon-02:before { content: "\EA07" }
.myfont-icon-03:before { content: "\EA08" }
.myfont-icon-04:before { content: "\EA09" }
.myfont-arrow_02:before { content: "\EA0A" }
.myfont-arrow_03:before { content: "\EA0B" }

@charset "UTF-8";
/*
html5doctor.com Reset Stylesheet
v1.6.1
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  /*font-size:98%;*/
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  color: #000;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

input, textarea {
  margin: 0;
  padding: 0;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th {
  text-align: left;
}

a:focus {
  outline: none;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.clearfix {
  min-height: 1px;
}

* html .clearfix {
  height: 1px;
  /*¥*/
  /*/
	height: auto;
	overflow: hidden;
	/**/
}

.both {
  clear: both;
}

.inline_block {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

img {
  max-width: 100%;
  height: auto;
}

table {
  width: 100%;
}

a, a:hover {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

a img:hover {
  opacity: 0.7;
  filter: alpha(opacity=70);
  /* ie lt 8 */
  -ms-filter: "alpha(opacity=70)";
  /* ie 8 */
  -moz-opacity: 0.7;
  /* FF lt 1.5, Netscape */
  -khtml-opacity: 0.7;
  /* Safari 1.x */
}

/*Firefoxだけに適用されるCSSハック*/
@-moz-document url-prefix() {
  a img:hover {
    opacity: 0.8;
  }
}

/*IE10以降に適用されるCSSハック（一応）*/
@media all and (-ms-high-contrast: none) {
  a img:hover {
    opacity: 0.7;
    filter: alpha(opacity=70);
    /* ie lt 8 */
    -ms-filter: "alpha(opacity=70)";
    /* ie 8 */
    -moz-opacity: 0.7;
    /* FF lt 1.5, Netscape */
    -khtml-opacity: 0.7;
    /* Safari 1.x */
  }
}

/*IE11だけに適用されるCSSハック*/
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, a img:hover {
    opacity: 0.7;
    filter: alpha(opacity=70);
    /* ie lt 8 */
    -ms-filter: "alpha(opacity=70)";
    /* ie 8 */
    -moz-opacity: 0.7;
    /* FF lt 1.5, Netscape */
    -khtml-opacity: 0.7;
    /* Safari 1.x */
  }
}

@media screen and (max-width: 479px) {
  a, abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, input, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, select, small, span, strong, sub, summary, sup, table, tbody, td, textarea, tfoot, th, thead, time, tr, ul, var, video {
    border: 0;
    font-size: 100%;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    outline: 0;
    padding: 0;
    text-decoration: none;
    vertical-align: baseline;
  }
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  ul, ol {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    outline: none;
  }
  img {
    vertical-align: bottom;
  }
  body {
    font-size: 14px;
    /*	line-height: 1;*/
    -webkit-text-size-adjust: none;
  }
  /************************************************************************↑リセットここまで*/
}

@keyframes bounceY {
  0% {
    transform: translateY(1em);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes line_animation {
  0% {
    stroke-dashoffset: 2000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes line_animation2 {
  0% {
    stroke-dashoffset: 100;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes line_animation3 {
  0% {
    stroke-dashoffset: 2000;
  }
  80% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 0;
  }
}

@keyframes line_animation4 {
  0% {
    stroke-dashoffset: 100;
  }
  80% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 0;
  }
}

@keyframes sizeScale {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
}

@keyframes handwriting_01 {
  0% {
    opacity: 0;
    transform: translateY(1em);
    stroke-dashoffset: 2000;
    fill: transparent;
  }
  50% {
    opacity: 1;
    transform: translateY(0);
    fill: transparent;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    stroke-dashoffset: 0;
    fill: #fff;
  }
}

@keyframes txEffect_01 {
  0% {
    opacity: 0;
    top: 2em;
  }
  80% {
    top: -.3em;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  color: #000;
  font-family: "Dosis", "Zen Kaku Gothic New", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Hiragino Sans", "ヒラギノ角ゴシック", Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  line-height: 1.8;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -webkit-text-rendering: optimizelegibility;
  font-size: 1.4rem;
  font-weight: 400;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  position: relative;
}

@media screen and (max-width: 768px) {
  body {
    height: 100dvh;
  }
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

::selection, ::-moz-selection {
  color: #fff;
  background-color: #eee;
}

p, span {
  font-weight: 400;
}

iframe {
  border: none;
}

a {
  text-decoration: none;
}

.pc {
  display: none !important;
}

.sp {
  display: block !important;
}

.pcInline {
  display: none !important;
}

.spInline {
  display: inline !important;
}

.pcInlineB {
  display: none !important;
}

.spInlineB {
  display: inline-block !important;
}

.pcTd {
  display: none !important;
}

.spTd {
  display: table-cell !important;
}

.pcTbl {
  display: none !important;
}

.spTbl {
  display: table !important;
}

a:hover img, a:active img {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

img {
  display: block;
  max-width: 100%;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.unqMrg {
  margin-top: -60px;
  padding-top: 60px;
}

html > body a.link_area {
  display: block;
  position: absolute;
  left: 0 !important;
  top: 0 !important;
  height: 100%;
  width: 100%;
  text-indent: -9999px;
  /*IE7、8用ハック*/
  background/*¥**/: #fff\9;
  /*IE7,8*/
  filter: alpha(opacity=0);
  -ms-filter: "alpha( opacity=0 )";
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  z-index: 80;
  cursor: pointer;
}

@media screen and (min-width: 769px) {
  .widedev {
    display: none !important;
  }
  .pc {
    display: block !important;
  }
  .sp {
    display: none !important;
  }
  .pcInline {
    display: inline !important;
  }
  .spInline {
    display: none !important;
  }
  .pcInlineB {
    display: inline-block !important;
  }
  .spInlineB {
    display: none !important;
  }
  .pcTd {
    display: table-cell !important;
  }
  .spTd {
    display: none !important;
  }
  .pcTbl {
    display: table !important;
  }
  .spTbl {
    display: none !important;
  }
}

@media screen and (min-width: 1920px) {
  .widedev {
    display: block !important;
  }
}

@media screen and (max-width: 320px) {
  .facebookWidget.sp {
    width: 100%;
  }
}

@font-face {
  font-family: "myfont";
  src: url("../fonts/myfont.eot");
  src: url("../fonts/myfont.eot?#iefix") format("eot"), url("../fonts/myfont.woff") format("woff"), url("../fonts/myfont.ttf") format("truetype"), url("../fonts/myfont.svg#myfont") format("svg");
  font-weight: normal;
  font-style: normal;
}

.myfont {
  display: inline-block;
  font-family: "myfont";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.myfont-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.myfont-2x {
  font-size: 2em;
}

.myfont-3x {
  font-size: 3em;
}

.myfont-4x {
  font-size: 4em;
}

.myfont-5x {
  font-size: 5em;
}

.myfont-fw {
  width: 1.2857142857142858em;
  text-align: center;
}

.myfont-arrow_01:before {
  content: "\EA01";
}

.myfont-radio-off:before {
  content: "\EA02";
}

.myfont-radio-on:before {
  content: "\EA03";
}

.myfont-check-off:before {
  content: "\EA04";
}

.myfont-check-on:before {
  content: "\EA05";
}

.myfont-icon-01:before {
  content: "\EA06";
}

.myfont-icon-02:before {
  content: "\EA07";
}

.myfont-icon-03:before {
  content: "\EA08";
}

.myfont-icon-04:before {
  content: "\EA09";
}

.myfont-arrow_02:before {
  content: "\EA0A";
}

.myfont-arrow_03:before {
  content: "\EA0B";
}

i {
  vertical-align: top;
}

i:before {
  vertical-align: top;
}

.lazyload {
  opacity: 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.lazyloaded {
  opacity: 1;
}

.lazyloaded + .loadImg {
  display: none !important;
}

/*.lazyload, .lazyloading {
 opacity: 0;
}
.lazyloaded{
  opacity: 1;
  transition: all 0.5s;
}*/
.setSp .sp-way {
  opacity: 0;
  transform: translateY(10px);
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.setSp .sp-way.is-active-sp {
  opacity: 1;
  transform: translateY(0);
}

.way {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.way.is-active, .showFlag .way {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

.autoWay {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded .autoWay {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

@media screen and (min-width: 769px) {
  .autoWay {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
    -moz-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .loaded .autoWay {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }
  .autoWay.active {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.fastWay {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.fastWay.active, .showContFlag .fastWay {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

.alphaWay {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transition-delay: 0s;
  -moz-transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.alphaWay.is-active {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.scaleWay {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: all 0.9s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.9s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: all 0.9s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 0.9s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.scaleWay.active {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.imgEffect_01 > span, .imgEffect_02 > span {
  display: block;
  transition: 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  overflow: hidden;
  position: relative;
  left: -100%;
  opacity: 0;
}

.imgEffect_01 > span img, .imgEffect_02 > span img {
  position: relative;
  left: 100%;
  transition: 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.imgEffect_01.is-active > span, .is-active.imgEffect_02 > span {
  left: 0;
  opacity: 1;
}

.imgEffect_01.is-active > span img, .is-active.imgEffect_02 > span img {
  left: 0;
}

.imgEffect_02 > span {
  left: auto;
  right: -100%;
}

.imgEffect_02 > span img {
  left: auto;
  right: 100%;
}

.imgEffect_02.is-active > span {
  left: auto;
  right: 0;
}

.imgEffect_02.is-active > span img {
  left: auto;
  right: 0;
}

.imgEffect_03 {
  display: block;
  opacity: 0;
  transform: translateY(30);
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
}

.imgEffect_03.is-active {
  opacity: 1;
  transform: translateY(0);
}

.imgEffect_04 {
  overflow: hidden;
  position: relative;
}

.imgEffect_04::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
}

.is-active .imgEffect_04::before {
  animation: 0.6s slideOutRight cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s forwards;
}

.imgEffect_05 {
  display: block;
  opacity: 0;
  transform: translateY(30);
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
}

.is-active .imgEffect_05 {
  opacity: 1;
  transform: translateY(0);
}

.imgEffect_06 {
  display: block;
  opacity: 0;
  transform: translateY(30);
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
}

.imgEffect_06.is-active {
  opacity: 1;
  transform: translateY(0);
}

@media screen and (min-width: 769px) {
  .imgEffect_06 {
    opacity: 1;
    transform: translateY(0);
  }
}

.txEffect_01 {
  overflow: hidden;
  position: relative;
  padding-top: .3em;
  opacity: 0;
}

.loaded .txEffect_01 {
  opacity: 1;
}

.txEffect_01 > span > span {
  position: relative;
  display: inline-block;
  opacity: 0;
  top: 2em;
  letter-spacing: .07em;
}

.txEffect_01 > span {
  display: block;
}

.is-active .txEffect_01 > span > span:nth-child(1) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.02s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(2) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.04s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(3) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.06s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(4) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.08s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(5) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.1s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(6) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.12s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(7) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.14s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(8) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.16s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(9) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.18s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(10) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.2s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(11) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.22s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(12) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.24s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(13) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.26s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(14) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.28s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(15) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.3s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(16) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.32s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(17) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.34s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(18) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.36s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(19) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.38s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(20) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.4s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(21) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.42s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(22) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.44s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(23) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.46s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(24) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.48s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(25) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.5s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(26) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.52s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(27) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.54s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(28) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.56s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(29) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.58s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(30) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.6s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(31) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.62s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(32) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.64s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(33) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.66s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(34) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.68s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(35) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.7s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(36) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.72s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(37) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.74s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(38) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.76s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(39) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.78s forwards;
}

.is-active .txEffect_01 > span > span:nth-child(40) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.8s forwards;
}

.txEffect_01.style_01 > span > span:nth-child(10) {
  display: block;
  height: 0;
}

@media screen and (min-width: 769px) {
  .txEffect_01.style_01 > span > span:nth-child(10) {
    display: inline;
    height: auto;
  }
}

.txEffect_02 {
  opacity: 0;
  transform: translateY(20px);
  transition: 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.is-active .txEffect_02 {
  opacity: 1;
  transform: translateY(0);
}

.txEffect_03 {
  overflow: hidden;
  position: relative;
  padding-top: .3em;
  opacity: 0;
}

.loaded .txEffect_03 {
  opacity: 1;
}

.txEffect_03 > span > span {
  position: relative;
  display: inline-block;
  opacity: 0;
  top: 2em;
  letter-spacing: .07em;
}

.is-active-item .txEffect_03 > span > span:nth-child(1) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.02s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(2) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.04s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(3) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.06s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(4) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.08s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(5) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.1s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(6) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.12s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(7) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.14s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(8) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.16s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(9) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.18s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(10) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.2s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(11) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.22s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(12) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.24s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(13) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.26s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(14) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.28s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(15) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.3s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(16) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.32s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(17) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.34s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(18) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.36s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(19) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.38s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(20) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.4s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(21) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.42s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(22) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.44s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(23) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.46s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(24) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.48s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(25) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.5s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(26) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.52s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(27) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.54s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(28) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.56s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(29) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.58s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(30) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.6s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(31) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.62s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(32) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.64s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(33) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.66s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(34) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.68s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(35) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.7s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(36) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.72s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(37) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.74s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(38) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.76s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(39) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.78s forwards;
}

.is-active-item .txEffect_03 > span > span:nth-child(40) {
  animation: txEffect_01 0.8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.8s forwards;
}

.txEffect_04 {
  opacity: 0;
  transform: translateY(20px);
  transition: 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.is-active-item .txEffect_04 {
  opacity: 1;
  transform: translateY(0);
}

.hiddenParts {
  display: none;
}

@keyframes linemove_01 {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
  50.01% {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

.grecaptcha-badge {
  display: none;
  bottom: 26.66667vw !important;
}

.page-template-page-order .grecaptcha-badge, .page-template-page-contact .grecaptcha-badge, .page-template-page-trial .grecaptcha-badge {
  display: block;
}

@media screen and (min-width: 769px) {
  .grecaptcha-badge {
    bottom: 100px !important;
  }
}

.desktop.setSp .luxy-el:not(.luxy-el.mainCatch) {
  transform: none !important;
}

.desktop.setSp .luxy-el.mainCatch {
  transform: rotate(90deg) !important;
}

.desktop.setSp.home .luxy-el.mainCatch {
  transform: none !important;
}

@media screen and (min-width: 769px) {
  .desktop.setSp .luxy-el.mainCatch {
    transform: rotate(0deg) !important;
  }
}

#txContainer {
  position: relative;
}

#txContainer > .inner > .item .imgStyle {
  display: block;
  position: relative;
}

#txContainer > .inner > .item .imgStyle img {
  max-width: none;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
}

@media screen and (min-width: 769px) {
  .widedev {
    display: none !important;
  }
  .pc {
    display: block !important;
  }
  .sp {
    display: none !important;
  }
  .pcInline {
    display: inline !important;
  }
  .spInline {
    display: none !important;
  }
  .pcInlineB {
    display: inline-block !important;
  }
  .spInlineB {
    display: none !important;
  }
  .pcTd {
    display: table-cell !important;
  }
  .spTd {
    display: none !important;
  }
  .pcTbl {
    display: table !important;
  }
  .spTbl {
    display: none !important;
  }
}

@media screen and (min-width: 1920px) {
  .widedev {
    display: block !important;
  }
}

@media screen and (max-width: 320px) {
  .facebookWidget.sp {
    width: 100%;
  }
}
